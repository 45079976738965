<template>
  <RWCard title="Upgrade/Downgrade">
    <template #body>
      <div v-if="httpCode === 200">
        <p>Upgrade/Downgrade the configurable options on this product.</p>
        <form method="post" v-if="upgrade.record">
          <table class="table table-striped">
            <thead>
              <tr>
                <th></th>
                <th>Current Configuration</th>
                <th></th>
                <th>New Configuration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ upgrade.record.addon }}</td>
                <td>{{ upgrade.record.old }}</td>
                <td>=&gt;</td>
                <td>
                  <b-form-select
                    v-model="selected"
                    :options="options"
                  ></b-form-select>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="text-center">
            <button
              @click="proses_calculate"
              type="button"
              class="btn btn-primary"
            >
              Click to Continue <i class="fa fa-angle-double-right"></i>
            </button>
          </p>
        </form>
      </div>
      <div v-else>
        <b-alert show variant="danger">{{ upgrade.message }}</b-alert>
      </div>
    </template>
  </RWCard>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { loading, ApiURL, notif } from "@/common/mixins/general.js";
export default {
  name: "upgrade-addons-cart",
  components: { RWCard },
  mixins: [loading, notif, ApiURL],
  props: {
    status: {
      required: true,
      type: Object
    },
    upgrade: {
      required: true,
      type: Object
    },
    httpCode: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      selected: ""
    };
  },
  methods: {
    emitToParent() {},
    proses_calculate() {
      if (this.selected === "") {
        this.mx_swal("Anda belum memilih paket upgrade!");
      } else {
        this.mx_loading();
        let post = {
          configoptions: {
            [this.upgrade.record.id]: this.selected
          }
        };

        this.xhr_hosting_post("upgrade_calc", post, this.status.id)
          .then(({ data }) => {
            // move data
            let postToParent = {
              component: "calculate",
              calculate: data
            };
            this.$emit("childToParent", postToParent);
          })
          .finally(() => {
            this.mx_loading(false);
          });
      }
    }
  },
  computed: {
    options() {
      let options = [];
      if (
        typeof this.upgrade !== "undefined" &&
        typeof this.upgrade.record.new !== "undefined"
      ) {
        let baru = this.upgrade.record.new;
        for (var i in baru) {
          if (i >= 1) {
            options.push({
              value: baru[i]["id"],
              text: baru[i]["optionname"] + " Rp" + baru[i]["price"]
            });
          } else {
            // options.push({value : baru[i]["id"], text : "No Change"})
          }
        }
      }
      return options;
    }
  }
};
</script>
