<template>
  <div>
    <div class="row">
      <div class="col-sm-3">
        <RWCard :bodyFit="true">
          <template #title>Up/Downgrade</template>
          <template #body>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <p>
                  Product/Service : <br />
                  <span class="font-weight-bold">{{ xhr.status.name }}</span>
                </p>
              </li>
              <li class="list-group-item">
                <p>
                  Domain : <br />
                  <span class="font-weight-bold">{{ xhr.status.domain }}</span>
                </p>
              </li>
            </ul>
          </template>
          <template #foot>
            <b-link
              :to="{ path: '/product/hosting/' + id }"
              class="btn btn-primary btn-block"
            >
              <i class="fas fa-arrow-circle-left"></i> Back to Service
              Details</b-link
            >
          </template>
        </RWCard>
      </div>
      <div class="col-sm-9">
        <div v-if="loading">
          <v-skeleton-loader type="paragraph@2"></v-skeleton-loader>
        </div>
        <div v-else>
          <component
            :is="view"
            :status="xhr.status"
            v-on:childToParent="onChildClick"
            :upgrade="xhr.addons"
            :httpCode="httpCode"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import upgrade from "@/views/pages/product/hosting/upgrade/upgrade-addons-cart.vue";
import calculate from "@/views/pages/product/hosting/upgrade/upgrade-addons-calculate.vue";

import { breadcrumb } from "@/common/mixins/general.js";
import { productHostingMixin } from "@/common/mixins/product/product-mixin.js";

export default {
  name: "upgrade-addons",
  mixins: [breadcrumb, productHostingMixin],
  components: { RWCard, upgrade, calculate },
  props: {
    id: {
      type: String,
      required: true
    },
    addons: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      httpCode: 400,
      xhr: {
        status: {},
        addons: {}
      },
      view: "upgrade",
      addons_product: ["sitepro", "backup"]
    };
  },
  methods: {
    data_upgrade() {
      this.xhr_hosting_post("upgrade_view", { addons: "Paket Backup" }, this.id)
        .then(({ data }) => {
          this.xhr.addons = data.data.upgrade;
          this.httpCode = 200;
        })
        .catch(({ response }) => {
          this.xhr.addons = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * --------------------------------------------------
     * dapat $emit dari child (cart) yg akan diteruskan
     * ke child calculate
     * --------------------------------------------------
     */
    onChildClick(params) {
      this.view = params.component;
      this.xhr.addons = params.calculate.data;
    }
  },
  mounted() {
    this.mx_hosting_status(this.id)
      .then(({ data }) => {
        this.xhr.status = data.status;
      })
      .finally(() => {
        this.loading = false;
        this.mx_breadcrumbHosting([
          {
            title: this.xhr.status.domain,
            route: "product/hosting/" + this.id
          },
          { title: "upgrade" },
          {
            title: this.addons,
            route: "product/hosting/" + this.id + "/upgrade/" + this.addons
          }
        ]);
        this.data_upgrade();
      });
  }
};
</script>
