<template>
  <RWCard title="Upgrade/Downgrade">
    <template #body>
      <b-alert show class="text-center">
        Current Configuration: {{ status.name }} ({{ status.domain }})
      </b-alert>

      <!-- table order -->
      <table class="table table-striped">
        <thead>
          <tr>
            <th width="60%">Description</th>
            <th width="40%" class="text-center">Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{ up.configname1 }}: {{ up.originalvalue1 }} =&gt;
              {{ up.newvalue1 }}
            </td>
            <td class="text-center">{{ up.price1 }}</td>
          </tr>
          <tr class="masspay-total">
            <td class="text-right">Subtotal:</td>
            <td class="text-center">{{ up.subtotal }}</td>
          </tr>
          <tr class="masspay-total">
            <td class="text-right">PPN @ {{ up.tax1_percent }}%:</td>
            <td class="text-center">{{ up.tax1_value }}</td>
          </tr>
          <tr class="masspay-total">
            <td class="text-right">Total Due Today:</td>
            <td class="text-center">{{ up.total_and_tax }}</td>
          </tr>
        </tbody>
      </table>

      <!-- form bottom -->
      <div class="row">
        <div class="col-sm-6">
          <form method="post">
            <h3>Payment Method</h3>
            <div class="form-group">
              <v-skeleton-loader
                type="text@2"
                v-if="loading.payment"
              ></v-skeleton-loader>
              <b-form-select
                v-model="selected"
                :options="options"
                v-else
              ></b-form-select>
            </div>
          </form>
        </div>
        <div class="col-sm-6 my-auto">
          <button
            type="button"
            @click="checkout()"
            class="btn btn-primary float-right"
          >
            Click to Continue >>
          </button>
        </div>
      </div>
    </template>
  </RWCard>
</template>

<script>
import { loading, ApiURL, notif } from "@/common/mixins/general.js";
import RWCard from "@/views/partials/content/Portlet.vue";
export default {
  name: "upgrade-addons-calculate",
  components: { RWCard },
  mixins: [loading, ApiURL, notif],
  props: {
    status: {
      required: true,
      type: Object
    },
    // data dari cart -> upgrade -> calculate
    upgrade: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      loading: {
        payment: true
      },
      selected: "",
      options: []
    };
  },
  methods: {
    /**
     * -------------------------------------------------
     * proses get metode pembayaran yang tersedia
     * -------------------------------------------------
     */
    get_paymentmethod() {
      this.xhr_payment("index")
        .then(({ data }) => {
          let payment = data.data.payment;
          for (var i in payment) {
            if (i < 1) {
              this.selected = payment[i]["gateway"];
            }
            this.options.push({
              value: payment[i]["gateway"],
              text: payment[i]["value"]
            });
          }
        })
        .finally(() => {
          this.loading.payment = false;
        });
    },

    /**
     * -------------------------------------------------
     * proses checkout upgrade
     * -------------------------------------------------
     */
    checkout() {
      let post = {
        paymentmethod: this.selected,
        configoptions: this.upgrade.configoptions
      };
      this.mx_loading();
      this.xhr_hosting_post("upgrade_exec", post, this.status.id)
        .then(() => {
          this.$router.push({ path: "/product/hosting/" + this.status.id });
        })
        .catch(err => {
          this.mx_swal(err);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    }
  },
  computed: {
    up() {
      return this.upgrade.upgrade;
    }
  },
  mounted() {
    this.get_paymentmethod();
  }
};
</script>
